import Back from '../components/Back/Back'


const NotFound = () => {
    return(
        <main className='notfound'>
            <h1>Error 404</h1>
            <h2>Not Found</h2>
            <Back />
        </main>
    )
}

export default NotFound