import './Brand.css'


const Brand = () => {
    return (
        <h1 className='brand'> 
            <img src='/img/logo.png' alt='Logo Fylend' title='Logo Fylend' /> 
            Fylend
        </h1>
    )
}

export default Brand