import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import './Back.css'


const Back = () => {
    return (
        <Link to={'/'}>
            <p>
                <FontAwesomeIcon icon={faChevronLeft} /> 
                <FormattedMessage id='button.back' defaultMessage='Back'/>
            </p>
        </Link>
    )
}

export default Back