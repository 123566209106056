import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import FileSender from '../pages/FileSender'
import FileReceiver from '../pages/FileReceiver'
// import Pay from '../pages/Pay'
import NotFound from '../pages/NotFound'


const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/send' element={<FileSender />} />
            <Route path='/receive' element={<FileReceiver />} />
            {/* <Route path='/pay' element={<Pay />} /> */}
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes