import AppRoutes from './routes/AppRoutes'
import './App.css'


function App() {
    return (
        <div className='App'>
            <AppRoutes />
        </div>
    )
}

export default App