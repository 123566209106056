import Brand from '../components/Brand/Brand'
import Hero from '../components/Hero/Hero'


const Home = () => {
    return (
        <main className='home'>
            <Brand />
            <Hero />
        </main>
    )
}

export default Home