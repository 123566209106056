import { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import fileTransferService from '../services/filetransfer.service'
import { FormattedMessage } from 'react-intl'
import Brand from '../components/Brand/Brand'
import Back from '../components/Back/Back'


const FileReceiver = () => {
    const [archivosPendientes, setArchivosPendientes] = useState([])
    const [error, setError] = useState(null)
    const [formData, setFormData] = useState({
        nombreUsuarioReceptor: '',
        nombreUsuarioRemitente: '',
        token: ''
    })

    const handleInputChange = e => {
        const { value, name } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const obtenerArchivos = () => {
            if (formData.nombreUsuarioReceptor && formData.nombreUsuarioRemitente && formData.token) {
                fileTransferService
                                    .obtenerArchivosPendientes(
                                            formData.nombreUsuarioReceptor, 
                                            formData.nombreUsuarioRemitente,
                                            formData.token)
                                    .then(response => {
                                        setArchivosPendientes(response.data.archivos)
                                        setError(null)
                                    })
                                    .catch(error => {
                                        console.error('Error getting pending files:', error)
                                        setArchivosPendientes([])
                                        setError('No pending files')
                                    })
            } else {
                setArchivosPendientes([])
                setError('Please enter your nicks when have confirmed the shipment.')
            }
        }

        obtenerArchivos()
    }, [formData.nombreUsuarioReceptor, formData.nombreUsuarioRemitente, formData.token])

    return (
        <main className='receiver'>
            <Back />
            <Brand />
            <hr />
            <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col md={6}>
                        <Card>
                            <h2>
                                <FormattedMessage id='receive.title' defaultMessage='Receive Transfer'/>
                            </h2>
                            <p>
                                <FormattedMessage 
                                    id='receive.text' 
                                    defaultMessage="Enter your destination nickname and the sender's nickname 
                                    to receive the file, when the sender has sent the file."/>
                            </p>
                            <form>
                                <label>
                                    <FormattedMessage id='receive.input1' defaultMessage='Nick Destination:'/>
                                </label>
                                <input 
                                    type='text' 
                                    name='nombreUsuarioReceptor' 
                                    value={formData.nombreUsuarioReceptor} 
                                    onChange={handleInputChange} />

                                <label>
                                    <FormattedMessage id='receive.input2' defaultMessage='Nick Emitter:'/>
                                </label>
                                <input 
                                    type='text' 
                                    name='nombreUsuarioRemitente'
                                    value={formData.nombreUsuarioRemitente} 
                                    onChange={handleInputChange} />

                                <label>
                                    <FormattedMessage id='receive.token' defaultMessage='Token:'/>
                                </label>
                                <input 
                                    type='text' 
                                    name='token'
                                    value={formData.token} 
                                    onChange={handleInputChange} />
                            </form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {error && <p>{error}</p>}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {archivosPendientes.map(archivo => (
                    <div key={archivo.token} 
                         style={{ 
                             margin: '10px', 
                             border: '1px solid #ccc', 
                             padding: '10px', 
                             minWidth: '200px' }}>
                        <h3>{archivo.originalname}</h3>
                        {archivo.originalname.match(/\.(jpg|jpeg|png|gif)$/) && (
                            <img
                                src={`data:image/*;base64,${archivo.thumbnail}`}
                                alt={archivo.originalname}
                                style={{ maxWidth: '100px', maxHeight: '100px' }} />
                            )}
                            <button onClick={() => {
                                        fileTransferService
                                                            .descargarArchivo(
                                                                archivo.token, 
                                                                formData.nombreUsuarioReceptor, 
                                                                formData.nombreUsuarioRemitente, 
                                                                formData.token)
                                                            .then(response => {
                                                                const url = window.URL.createObjectURL(new Blob([response.data]))
                                                                const link = document.createElement('a')
                                                                link.href = url
                                                                link.setAttribute('download', archivo.originalname)
                                                                document.body.appendChild(link)
                                                                link.click()
                                                            })
                                                            .catch(error => console.error('Error al descargar archivo:', error))
                                    }}>
                            <FormattedMessage id='receive.button' defaultMessage='Download'/>
                        </button>
                    </div>
                ))}
            </div>
        </main>
    )
}

export default FileReceiver