import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneDeparture, faPlaneArrival } from '@fortawesome/free-solid-svg-icons'
import { langContext } from './../../context/lang.context'
import { FormattedMessage } from 'react-intl'
import './Hero.css'


const Hero = () => {
	const lang = useContext(langContext)
    return (
        <>
            <hr />
            <section className='lang'>
                <button onClick={() => lang.establecerLenguaje('es-ES')}>
                    Español
                </button>
                <button onClick={() => lang.establecerLenguaje('en-US')}>
                    English
                </button>
            </section>
            <hr />
            <p>
                <FormattedMessage 
                    id='home.text1' 
                    defaultMessage='Your application for secure and private file transfers'/>
            </p>
            <p>
                <FormattedMessage 
                    id='home.text2' 
                    defaultMessage='If you are sending a file, select whether you want to send a file first. 
                    If you are going to receive it, wait for the transfer to take place before entering the nicks.'/>
            </p>
            <hr />
            <Container>
                <Row className='my-5'>
                    <Col xs={6} className='d-flex justify-content-center align-items-center'>
                        <Card>
                            <Link to={'/send'}>
                                <FontAwesomeIcon icon={faPlaneDeparture} /> 
                                <p>
                                    <FormattedMessage id='home.send' defaultMessage='Send file'/>
                                </p>
                            </Link>
                        </Card>
                    </Col>
                    <Col xs={6} className='d-flex justify-content-center align-items-center'>
                        <Card>
                            <Link to={'/receive'}>
                                <FontAwesomeIcon icon={faPlaneArrival} /> 
                                <p>
                                    <FormattedMessage id='home.receive' defaultMessage='Receive file'/>
                                </p>
                            </Link>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <hr />
            <section>
                <p>
                    Created by <a href='https://thebulliam.com'>The Bulliam</a> and powered with 
                    React, Boostrap, Netlify, ExpressJS, NodeJS and Render.
                </p>
                <hr />
                <p>&copy;2024 Fylend - All rights reserved</p>
            </section>
        </>
    )
}

export default Hero