import React, { createContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import Spanish from './../data/es-ES.json'
import English from './../data/en-US.json'

const langContext = createContext()

const LangProvider = ({ children }) => {
	let localeDefault = 'en-US'
	let messagesDefault = English
	const lang = localStorage.getItem('lang')

	if (lang) {
		localeDefault = lang

		if (lang === 'es-ES') {
			messagesDefault = Spanish
		}
	}

	const [messages, setMessages] = useState(messagesDefault)
	const [locale, setLocale] = useState(localeDefault)

	const establecerLenguaje = (lang) => {
		switch (lang){
			case 'en-US':
				setMessages(English)
				setLocale('en-US')
				localStorage.setItem('lang', 'en-US')
				break
			case 'es-ES':
				setMessages(Spanish)
				setLocale('es-ES')
				localStorage.setItem('lang', 'es-ES')
				break
			default:
				setMessages(English)
				setLocale('en-US')
				localStorage.setItem('lang', 'en-US')
		}
	}

	return (
		<langContext.Provider value={{ establecerLenguaje }}>
			<IntlProvider locale={locale} messages={messages}>
				{children}
			</IntlProvider>
		</langContext.Provider>
	)
}
 
export { LangProvider, langContext }